import React, { useState } from 'react';
import './styles.css'; // Asegúrate de que este archivo CSS esté vinculado correctamente

const MuertosAltar = ({ data }) => {
  const [activeElement, setActiveElement] = useState(null);

  const items = data.altar_items; // Obtén los elementos del arreglo 'altar_items'

  const handleClick = (id) => {
    setActiveElement(id === activeElement ? null : id);
  };

  return (
    <div className="muertosAltar_container">
      <h2 className='barrios-header-title muertosdestinos_mt30'>{ data.altarTitle }</h2>
      <img src={require('../../assets/img/muertos/muertos_altar_2.jpg')} alt="Altar de Muertos" className="muertosAltar_image" />

      {items.map(item => (
        <div
          key={item.id}
          className={`muertosAltar_pulseButton muertosAltar_${item.id}`}
          onClick={() => handleClick(item.id)}
        >
          {activeElement === item.id && (
            <div className={`muertosAltar_infoBox muertosAltar_infoBox-${item.id}`}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MuertosAltar;